import React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import parse from "html-react-parser"
import Seo from "../components/seo"
import * as containerStyles from "~/components/pages/blog/blog.module.scss"
import Arrow from "~/images/common_icon_arrow_r.svg"
import TagList from "../components/modules/tagList"
import Pagination from "../components/modules/pagination"

export default function BlogTagIndex({ data, pageContext }) {
  const posts = data.allWpPost.nodes

  if (!posts.length) {
    return <p>No Found</p>
  }

  return (
    <>
      <Seo
        title={pageContext.tagName}
        pageUrl={`/tag/${pageContext.pageContext}`}
      />

      <div className="blog">
        <nav className="contentComponent">
          <ul className="breadcrumbComponent mincho">
            <li>
              <Link to="/" itemProp="url">
                TOP
              </Link>
              <span>
                <Arrow />
              </span>
            </li>
            <li>
              <Link to="/blog" itemProp="url">
                TAG
              </Link>
              <span>
                <Arrow />
              </span>
            </li>
            <li>#{pageContext.tagName}</li>
          </ul>
        </nav>
        <section>
          <header className="borderLine titleComponent">
            <h1 className="contentComponent heading with-english">
              <span>blog</span>#{pageContext.tagName}
            </h1>
          </header>
          <div className={containerStyles.articleList}>
            {posts.map(post => {
              const title = post.title
              const featuredImage = {
                image:
                  post.featuredImage?.node?.localFile?.childImageSharp
                    ?.gatsbyImageData,
                alt: post.featuredImage?.node?.alt || ``,
              }
              const firstCategory = {
                name: post.categories?.nodes[0]?.name,
                uri: post.categories?.nodes[0]?.uri,
              }
              const tags = post.tags?.nodes

              return (
                <article
                  className={containerStyles.articleItem}
                  itemScope
                  itemType="http://schema.org/Article"
                  key={post.id}
                >
                  <Link
                    to={`/blog/${post.id}`}
                    itemProp="url"
                    className="contentComponent"
                  >
                    <div className={containerStyles.thmbnail}>
                      {featuredImage?.image ? (
                        <GatsbyImage
                          image={featuredImage.image}
                          alt={featuredImage.alt}
                        />
                      ) : (
                        <StaticImage
                          src="../images/top_mv_pc.jpg"
                          alt=""
                          placeholder="blurred"
                          layout="fullWidth"
                        />
                      )}
                    </div>
                    <section className={containerStyles.articleDetail}>
                      <header className="mincho">
                        {firstCategory?.name && (
                          <span className={containerStyles.articleCategory}>
                            {firstCategory.name}
                          </span>
                        )}
                        <h2
                          className={
                            containerStyles.articleTitle +
                            " heading is-secondary"
                          }
                        >
                          <span itemProp="headline">{parse(title)}</span>
                        </h2>
                      </header>
                      <small
                        className={containerStyles.articleDate + " mincho"}
                      >
                        {post.date}
                      </small>
                      <div
                        className={containerStyles.articleExpert}
                        itemProp="description"
                        dangerouslySetInnerHTML={{ __html: post.excerpt }}
                      />

                      {tags.length > 0 && <TagList type={'tag'} tags={tags} />}
                    </section>
                  </Link>
                </article>
              )
            })}
          </div>
        </section>

        <Pagination pageContext={pageContext} />
      </div>
    </>
  )
}

export const pageQuery = graphql`
  query WordPressPostTagArchive($id: String!, $offset: Int!, $postsPerPage: Int!) {
    allWpPost(
      sort: { fields: [date], order: DESC }
      filter: { tags: { nodes: { elemMatch: { id: { eq: $id } } } } }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        uri
        id
        date(formatString: "YYYY.MM.DD")
        title
        excerpt
        tags {
          nodes {
            uri
            name
            slug
          }
        }
        categories {
          nodes {
            name
            uri
          }
        }
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  placeholder: BLURRED
                  layout: FULL_WIDTH
                )
              }
            }
          }
        }
      }
    }
  }
`
